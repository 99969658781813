import moment from "moment";
import PDFMerger from "pdf-merger-js/browser";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { ReactSVG } from "react-svg";
import { Dispatch } from "redux";
import { AttributeName, MedicalRecordState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import DropzoneSelectFile from "src/components/DropzoneSelectFile/DropzoneSelectFile";
import { MainButton } from "src/components/button/mainButton";
import FilePicker from "src/components/filePicker/filePicker";
import FilePickerMultiple from "src/components/filePicker/filePickerMultiple";
import { Input } from "src/components/input";
import { Label } from "src/components/label/label";
import { ListGalerry } from "src/components/listGalerry";
import ImgWrapper from "src/components/listGalerry/imgWrapper";
import { ListGalerry3D } from "src/components/listGalerry/listGalerry3D";
import { MedicalTable } from "src/components/medicalTable";
import NewImageNamePopup from "src/components/popup/imagetype";
import MedicalPopup from "src/components/popup/medicalPopup";
import { Table } from "src/components/table";
import { TeethSVG } from "src/components/teethSVG/teethSVG";
import { MAX_FILE, Strings } from "src/constants";
import { IUser, userFromJson } from "src/features/account/data/models/user";
import { MedicalField, getMetaData } from "src/features/medicalRecord/data/models/attribute";
import { getFileNameFromMFile, hasData, mFileFromJson } from "src/features/medicalRecord/data/models/file";
import { IMedicalRecord, defaultRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { MedicalRecordContainer } from "src/features/medicalRecord/presenter/container/medicalRecordContainer";
import {
  medicalRecordStateChanged,
  selectedMedicalRecordChanged,
  selectedMedicalRecordDataUpdated,
  selectedToothChanged,
  updateMedicalSubmiting,
} from "src/features/medicalRecord/presenter/store/medicalRecord";
import { selectedPatientUpdated } from "src/features/patient/presenter/store";
import { changeModal } from "src/features/root/presenter/store/root";
import ThreeDViewerPopup from "src/features/threeDViewer/presenter/ui/pages/index/threeDViewerPopup";
import { FileTypes, Modals, Routes, history } from "src/helpers";
import { TeethIndex } from "src/helpers/teethIndex";
import MainLayout from "src/layouts/main/mainLayout";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { PLYExporter } from "src/threeJs/exporters/PLYExporter";
import { Assets } from "src/utils/assets";
import { findParamsFromSearchString, findSubRouteFromPathName, uploadFileToS3 } from "src/utils/utils";
import * as THREE from "three";

interface MedicalManagementDetailState {
  number: string;
  patientId: string;
  dentistId: string;
  patientName: string;
  orthodontistName: string;
  listGallery: MedicalField[];
  isConverting: boolean;
  threeDScene?: THREE.Scene;
  typingTimeout: any;
}

class MedicalManagementDetail extends React.Component<MedicalManagementDetailType, MedicalManagementDetailState> {
  constructor(props: any) {
    super(props);
    this.state = {
      number: "",
      patientId: "",
      dentistId: "",
      patientName: "",
      orthodontistName: "",
      listGallery: [],
      isConverting: false,
      threeDScene: undefined,
      typingTimeout: undefined,
    };
  }

  _filterAttributes() {
    const allFields = [...getMetaData(AttributeName.imageType, this.props.attributes)];
    const nonDentalFields = [...allFields.filter((field) => field.name.indexOf("dental") !== 0)];
    let dentalFields = [...allFields.filter((field) => field.name.indexOf("dental") > -1)];

    if (this.props.selectedMedicalRecord.images?.length > 0) {
      const imageDentalFields: MedicalField[] = [];
      this.props.selectedMedicalRecord.images
        .filter((image) => image.image_type.indexOf("dental") > -1)
        .forEach((image: any, imageIndex: number) => {
          imageDentalFields.push({
            name: `dental${imageIndex === 0 ? "" : imageIndex}`,
            type: "file",
            label: image.title,
          });
        });

      if (imageDentalFields.length > 0) dentalFields = [...imageDentalFields];
    }

    this.setState({
      ...this.state,
      listGallery: [...nonDentalFields, ...dentalFields],
    });
  }

  componentDidMount() {
    if (this.props.attributes.length === 0) this.props.onFetchAttributes().then(() => this._filterAttributes());
    else this._filterAttributes();

    let params = findParamsFromSearchString(window.location.search);
    let currentRoute = findSubRouteFromPathName(window.location.pathname);
    if (currentRoute === Routes.MEDICAL_MANAGEMENT_CREATE) {
      //if this is create route, check if linking record id is available
      if (
        !params?.has("linkingRecordId") ||
        !params.get("linkingRecordId") ||
        Array.from(this.props.selectedMedicalRecord.files.keys()).length === 0
      ) {
        //if linking record id not found, consider this is create new record flow
        this.props.selectedRecordChanged({
          ...defaultRecord(),
          isNotFound: false,
          patient: this.props.selectedMedicalRecord.patient,
        });
      }
    } else {
      //if this is edit route, make additional validation
      if (params?.has("recordId") && params.get("recordId")) {
        //fetch record info by id
        this.props.onSelectMedicalRecord(params.get("recordId")).then((_) => {
          this._filterAttributes();
        });
      } else {
        this.props.selectedRecordChanged({
          ...defaultRecord(),
          isNotFound: true,
        });
      }
    }
  }

  handleUpdateScene(scene: THREE.Scene) {
    const exporter = new PLYExporter();

    this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles);

    exporter.parse(scene, async (result: any) => {
      const sceneFile: any = new File([new Blob([result], { type: "text/plain" })], "噛み合わせ.ply");
      const sceneMap = new Map<string, any>();

      const resultSceneFile = await uploadFileToS3(sceneFile);
      sceneFile.location = resultSceneFile.location;

      sceneMap.set("ply3", sceneFile);
      this.props.onConvertPlyFiles(sceneMap);
    });

    this.setState({ threeDScene: scene });
  }

  handleDeleteThreeDScene() {
    this.setState({ threeDScene: undefined });
  }

  handleDragEnd = (result: any) => {
    if (result.destination) {
      const type = result.draggableId;
      const destinationType = result.destination.droppableId;

      const [imageDrag] = this.props.selectedMedicalRecord.images.filter((image) => image.image_type === type);
      const [imageDestination] = this.props.selectedMedicalRecord.images.filter(
        (image) => image.image_type === destinationType
      );

      if (!imageDestination) {
        const imageDragIndex = this.props.selectedMedicalRecord.images.indexOf(imageDrag);
        const destinationLabel = this.state.listGallery.filter((gallery) => gallery.name === destinationType)[0].label!;

        imageDrag.isUpdate = true;
        imageDrag.image_type = destinationType;
        imageDrag.label = destinationLabel;

        this.props.selectedMedicalRecord.images[imageDragIndex] = imageDrag;
      } else {
        if (imageDestination.image_type.indexOf("dental") && imageDrag.image_type.indexOf("dental")) {
          const idTemp = imageDrag.id;
          imageDrag.id = imageDestination.id;
          imageDestination.id = idTemp;
        }

        // Update image destination
        const imageDestinationIndex = this.props.selectedMedicalRecord.images.indexOf(imageDestination);
        imageDestination.isUpdate = true;
        imageDestination.image_type = imageDrag.image_type;
        imageDestination.label = imageDrag.label;
        this.props.selectedMedicalRecord.images[imageDestinationIndex] = imageDestination;

        // Update image drag
        const imageDragIndex = this.props.selectedMedicalRecord.images.indexOf(imageDrag);
        const destinationLabel = this.state.listGallery.filter((gallery) => gallery.name === destinationType)[0].label!;
        imageDrag.isUpdate = true;
        imageDrag.image_type = destinationType;
        imageDrag.label = destinationLabel;
        this.props.selectedMedicalRecord.images[imageDragIndex] = imageDrag;
      }

      this.props.onChangeMedicalRecordData("images", this.props.selectedMedicalRecord.images);
    }
  };

  buildMedicalRecordDetailTable = (plyKeys: string[], stlKeys: string[]) => {
    return (
      <Table
        className="dataTable-container"
        subClassName="table table_detail"
        rows={[
          {
            fields: [
              {
                colSpan: 2,
                html: (
                  <div className="horizontal space-between">
                    <div className="inputGroup medical-detail-date-picker">
                      <label htmlFor="">日付</label>
                      <DatePicker
                        dateFormat="yyyy/MM/dd HH:mm:ss"
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        className="form-control"
                        selected={
                          moment(this.props.selectedMedicalRecord.created_at).isValid()
                            ? moment(this.props.selectedMedicalRecord.created_at).toDate()
                            : moment().toDate()
                        }
                        onChange={(date) => {
                          this.props.onChangeMedicalRecordData("created_at", date);
                        }}
                      />
                    </div>
                    <div className="inputGroup">
                      <label htmlFor="">患者名/カルテ番号</label>
                      <Select
                        className={`basic-multi-select form-icosearch ${
                          this.props.selectedMedicalRecord.file_ids ? "no-interaction" : ""
                        }`}
                        placeholder={"選択してください"}
                        onMenuOpen={() => {
                          this.setState(
                            {
                              ...this.state,
                              patientName: this.props.selectedMedicalRecord.patient.full_name,
                            },
                            () => this.props.onSearchListPatients(this.props.selectedMedicalRecord.patient.full_name)
                          );
                        }}
                        options={this.props.searchedPatients.data.map((patient) => {
                          return {
                            value: patient?.special_code,
                            label: patient.full_name,
                          };
                        })}
                        noOptionsMessage={() => {
                          return Strings.NO_DATA;
                        }}
                        value={{
                          value: this.props.selectedMedicalRecord.patient.special_code,
                          label: this.props.selectedMedicalRecord.patient.full_name
                            ? this.props.selectedMedicalRecord.patient.full_name
                            : "選択してください",
                        }}
                        onChange={(newValue) => {
                          this.handleSelectPatient(newValue);
                        }}
                        inputValue={this.state.patientName}
                        onInputChange={(value) => {
                          const self = this;

                          if (self.state.typingTimeout) clearTimeout(self.state.typingTimeout);

                          self.setState({
                            patientName: value,
                            typingTimeout: setTimeout(() => self.props.onSearchListPatients(value), 500),
                          });
                        }}
                      />
                    </div>
                    <div className="inputGroup">
                      <label htmlFor="">共有先</label>
                      <Select
                        onInputChange={(value) => {
                          this.setState({
                            ...this.state,
                            orthodontistName: value,
                          });
                          this.props.onSearchOthordontists(value);
                        }}
                        isMulti
                        noOptionsMessage={() => {
                          return Strings.NO_DATA;
                        }}
                        placeholder={"選択してください"}
                        onMenuOpen={() => {
                          this.props.onSearchOthordontists(this.state.orthodontistName ?? "");
                        }}
                        onChange={(newValues) => {
                          let datas = newValues.map((newValue) => userFromJson(JSON.parse(newValue.value)));
                          this.props.onChangeMedicalRecordData("orthodontists", [...datas]);
                          this.props.onSearchOthordontists(this.state.orthodontistName ?? "");
                        }}
                        options={this.props.searchedOrthodontists.data.map((orthodontist, index) => {
                          return {
                            value: JSON.stringify(orthodontist),
                            label: orthodontist.full_name,
                          };
                        })}
                        value={this.props.selectedMedicalRecord.orthodontists
                          .filter((item) => item.id !== "")
                          .map((orthodontist) => {
                            return {
                              value: JSON.stringify(orthodontist),
                              label: orthodontist.full_name ? orthodontist.full_name : "選択してください",
                            };
                          })}
                        className="basic-multi-select form-icosearch"
                      />
                    </div>
                  </div>
                ),
              },
            ],
          },
          {
            fields: [
              {
                html: <div>{Strings.IMAGE_INSIDE}</div>,
                className: "vertical-initial",
              },
              {
                html: (
                  <div className="relative">
                    <ListGalerry
                      listGalerry={this.state.listGallery}
                      images={this.props.selectedMedicalRecord.images}
                      onDeleteImage={(type) => {
                        let allFields = [...getMetaData(AttributeName.imageType, this.props.attributes)];

                        if (allFields.every((field) => field.name !== type))
                          this.setState({
                            ...this.state,
                            listGallery: [...this.state.listGallery.filter((gallary) => gallary.name !== type)],
                          });

                        this.props.selectedMedicalRecord.images.map((imageUpdate) => {
                          if (imageUpdate.image_type === type) {
                            imageUpdate.isUpdate = true;
                            imageUpdate.path = "";
                          }

                          return imageUpdate;
                        });

                        this.props.onChangeMedicalRecordData("images", this.props.selectedMedicalRecord.images);
                      }}
                      onAddNewDentalType={() => {
                        this.props.changeModal(Modals.IMAGE_TYPE);
                      }}
                      onChange={(
                        type?: string,
                        value?: string,
                        label?: string,
                        e?: React.ChangeEvent<HTMLInputElement>,
                        file?: any,
                        url?: string,
                        fileList?: any[]
                      ) => {
                        if (!fileList) this.updateGalary(type!, value!, label!, e, file, url);
                        else this.updateMultipleGallery(e, fileList!);
                      }}
                      isDragAndDrop
                      multiple
                    />
                  </div>
                ),
                className: "",
              },
            ],
          },
          {
            fields: [
              {
                html: (
                  <p>
                    患者様への共有
                    <br />
                    添付ファイル
                    <br />
                    *PDFのみ
                  </p>
                ),
              },
              {
                html: (
                  <div className="horizontal blockPdf">
                    <FilePickerMultiple
                      className="btnpdf-wrapper relative"
                      labelClassName="btn btn-primary btn-main"
                      id="pdf1"
                      hasFile={hasData(this.props.selectedMedicalRecord.pdfs?.patient?.file)}
                      labelText={
                        this.props.selectedMedicalRecord.pdfs?.patient &&
                        getFileNameFromMFile(Strings.SELECT_FILE, this.props.selectedMedicalRecord.pdfs?.patient?.file)
                      }
                      inputClassName="w300px form-control mr10"
                      onDelete={() => {
                        this.props.onChangeMedicalRecordData("pdfs", {
                          ...this.props.selectedMedicalRecord.pdfs,
                          patient: {
                            ...this.props.selectedMedicalRecord.pdfs?.patient,
                            file: {
                              ...this.props.selectedMedicalRecord.pdfs?.patient.file,
                              isUpdate: true,
                              path: "",
                              base64: "",
                              name: "",
                            },
                          },
                        });
                      }}
                      onChange={async (_: any, __: any, inputfile: any, url?: string, fileList?: any) => {
                        if (inputfile) {
                          const file = mFileFromJson({
                            isUpdate: true,
                            path: url,
                            file: inputfile,
                            name: inputfile.name,
                          });
                          this.props.onChangeMedicalRecordData("pdfs", {
                            ...this.props.selectedMedicalRecord.pdfs,
                            patient: {
                              ...this.props.selectedMedicalRecord.pdfs?.patient,
                              file: file,
                            },
                          });
                        }
                        if (fileList?.length > 1) {
                          const merger = new PDFMerger();

                          for await (const inputfile of fileList) {
                            if (inputfile instanceof File) {
                              await merger.add(inputfile);
                            } else {
                              await fetch(inputfile.url).then(async (res) => {
                                const fileBlob = await res.blob();
                                const newFile = new File([fileBlob], `${inputfile.name}.pdf`);

                                await merger.add(newFile);
                              });
                            }
                          }

                          const mergedPdfBlob = await merger.saveAsBlob();
                          const newMergedFile = new File([mergedPdfBlob], "説明資料まとめ.pdf");
                          const newMergedFileS3Result = await uploadFileToS3(newMergedFile);

                          const file = mFileFromJson({
                            isUpdate: true,
                            path: newMergedFileS3Result.location,
                            file: newMergedFile,
                            name: newMergedFile.name,
                          });
                          this.props.onChangeMedicalRecordData("pdfs", {
                            ...this.props.selectedMedicalRecord.pdfs,
                            patient: {
                              ...this.props.selectedMedicalRecord.pdfs?.patient,
                              file: file,
                            },
                          });
                        }
                      }}
                      fileAccept={FileTypes.PDF}
                    />
                    <Input
                      className="fsc comment ml10"
                      inputClassName="form-control "
                      inputPlaceHolder="歯をよく磨きましょう！"
                      value={this.props.selectedMedicalRecord.pdfs?.patient.comment}
                      onChange={(value) => {
                        this.props.onChangeMedicalRecordData("pdfs", {
                          ...this.props.selectedMedicalRecord.pdfs,
                          patient: {
                            ...this.props.selectedMedicalRecord.pdfs?.patient,
                            comment: value,
                          },
                        });
                      }}
                    />
                  </div>
                ),
              },
            ],
          },
          {
            fields: [
              { html: Strings.TEETH_SVG, className: "vertical-initial" },
              {
                html: (
                  <TeethSVG
                    teeths={TeethIndex}
                    teethDisabled={
                      this.props.selectedMedicalRecord.teeth
                        ? [
                            ...Array.from(this.props.selectedMedicalRecord.teeth?.keys()).filter(
                              (key) =>
                                this.props.selectedMedicalRecord.teeth?.get(key)?.get(AttributeName.pattern)?.value ===
                                "4"
                            ),
                          ]
                        : []
                    }
                    openModal={(toothIndex: number) => {
                      this.props.selectedToothChanged(toothIndex);
                      this.props.changeModal(Modals.MEDICAL);
                    }}
                  />
                ),
              },
            ],
          },

          {
            fields: [
              {
                colSpan: 2,
                html: (
                  <MedicalTable
                    teeth={this.props.selectedMedicalRecord.teeth ?? new Map()}
                    attributes={this.props.attributes}
                  />
                ),
              },
            ],
          },
          {
            fields: [
              { html: Strings.SMOKING },
              {
                html: (
                  <Table
                    headers={[]}
                    rows={[
                      {
                        fields: [
                          {
                            html: (
                              <Label text={Strings.CAN} className="fsc mr30" htmlFor="isSmoking1">
                                <Input
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={true}
                                  name="isSmoking"
                                  checked={this.props.selectedMedicalRecord.is_smoking === 1}
                                  onChange={(checked) => {
                                    if (checked) this.props.onChangeMedicalRecordData("is_smoking", checked ? 1 : 0);
                                  }}
                                  id="isSmoking1"
                                />
                              </Label>
                            ),
                          },
                          {
                            html: (
                              <Label text={Strings.CANNOT} className="fsc mr30" htmlFor="isSmoking2">
                                <Input
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={false}
                                  name="isSmoking"
                                  checked={this.props.selectedMedicalRecord.is_smoking === 0}
                                  onChange={(checked) => {
                                    if (checked) this.props.onChangeMedicalRecordData("is_smoking", checked ? 0 : 1);
                                  }}
                                  id="isSmoking2"
                                />
                              </Label>
                            ),
                          },
                        ],
                      },
                    ]}
                  ></Table>
                ),
              },
            ],
          },
          {
            fields: [
              { html: "PLY（⼝腔内）", className: "vertical-initial" },
              {
                html: (
                  <div
                    className={`list-galerry img-wrapper ${
                      plyKeys.length === MAX_FILE
                        ? "img-wrapper-custom2 d-flex"
                        : plyKeys.length > 0
                        ? "img-wrapper-custom d-flex"
                        : ""
                    }`}
                  >
                    {plyKeys.map((key, idx) => {
                      let item = this.props.selectedMedicalRecord.files.get(key);

                      if (item?.name?.includes("噛み合わせ"))
                        return (
                          <div key={idx} className={"col-lg-3 col-4 img-wrapper"}>
                            <figure>
                              <div
                                onClick={() => {
                                  if (item) {
                                    this.props.selectedMedicalRecord.files.set(key, {
                                      ...item,
                                      path: "",
                                      base64: "",
                                      blob: undefined,
                                      isUpdate: true,
                                    });
                                    if (key.indexOf("ply") === 0) {
                                      this.props.selectedMedicalRecord.files.set("whole", {
                                        ...item,
                                        path: "",
                                        base64: "",
                                        blob: undefined,
                                        isUpdate: true,
                                      });
                                    }
                                  }
                                  this.props.onChangeMedicalRecordData("files", this.props.selectedMedicalRecord.files);
                                }}
                                className="delete-btn"
                              >
                                <img className="close-icon" src={Assets.close} alt="" />
                              </div>
                              <div className="images">
                                <img src={Assets.ply} alt={"噛み合わせ"} />
                              </div>
                            </figure>
                            <Label className="btn btn-outline-primary" text={"噛み合わせ"} />
                          </div>
                        );

                      return (
                        <ImgWrapper
                          key={idx}
                          className="mr10 col-4 col-lg-3"
                          imgURL={item?.blob || item?.path ? Assets.ply : Assets.medicalImage}
                          hadImage={plyKeys.indexOf(key) === plyKeys.length - 1}
                          label={item?.label ?? item?.file?.name!}
                          onDelete={() => {
                            if (item) {
                              this.props.selectedMedicalRecord.files.set(key, {
                                ...item,
                                path: "",
                                base64: "",
                                blob: undefined,
                                isUpdate: true,
                              });
                              if (key.indexOf("ply") === 0) {
                                this.props.selectedMedicalRecord.files.set(key === "ply1" ? "upper" : "lower", {
                                  ...item,
                                  path: "",
                                  base64: "",
                                  blob: undefined,
                                  isUpdate: true,
                                });
                              }
                            }
                            this.props.onChangeMedicalRecordData("files", this.props.selectedMedicalRecord.files);
                            this.handleDeleteThreeDScene();
                          }}
                          onClick={(e) => {}}
                          onChange={(_, __, file) => {
                            let plyFiles = new Map<string, any>();
                            plyFiles.set(key, file);
                            this.props.onConvertPlyFiles(plyFiles);
                          }}
                          fileAccept={FileTypes.PLY}
                        />
                      );
                    })}
                    {
                      <DropzoneSelectFile
                        className={`${plyKeys.length >= MAX_FILE ? "hide" : ""} upload_wrapper`}
                        onChange={(acceptedFiles: any[]) => {
                          let plyFiles = new Map<string, any>();

                          for (let i = 0; i < acceptedFiles.length; i++) {
                            if (plyKeys.length + i + 1 > MAX_FILE) break;

                            plyFiles.set(`ply${plyKeys.length + i + 1}`, acceptedFiles[i]);
                          }

                          this.props.onConvertPlyFiles(plyFiles);
                        }}
                        fileAccept={FileTypes.PLY}
                      />
                    }
                    {plyKeys.length === MAX_FILE && this.props.selectedMedicalRecord.id === "" ? (
                      <ThreeDViewerPopup
                        selectedMedicalRecord={this.props.selectedMedicalRecord}
                        plyKeys={plyKeys}
                        threeDScene={this.state.threeDScene}
                        handleUpdateScene={(scene: THREE.Scene) => this.handleUpdateScene(scene)}
                      />
                    ) : null}
                  </div>
                ),
              },
            ],
          },
          {
            fields:
              stlKeys.length === 0
                ? []
                : [
                    { html: "STL（⼝腔内）", className: "vertical-initial" },
                    {
                      html: (
                        <ListGalerry3D
                          listGalerry={getMetaData(AttributeName.file3DType, this.props.attributes).map((item) => {
                            return {
                              ...item,
                              label:
                                (this.props.selectedMedicalRecord.files.get(item.name) &&
                                  this.props.selectedMedicalRecord.files.get(item.name)?.name) ||
                                item.label,
                            };
                          })}
                          files={this.props.selectedMedicalRecord.files}
                          onChange={() => {}}
                          readonly
                        />
                      ),
                    },
                  ],
          },
          {
            fields: [
              {
                html: (
                  <p>
                    共有先への
                    <br />
                    コメント
                  </p>
                ),
                className: "vertical-initial",
              },
              {
                html: (
                  <textarea
                    name="Text1"
                    className="form-control doctor-comment"
                    cols={40}
                    rows={5}
                    value={this.props.selectedMedicalRecord.pdfs?.orthodontist.comment}
                    onChange={(e) => {
                      this.props.onChangeMedicalRecordData("pdfs", {
                        ...this.props.selectedMedicalRecord.pdfs,
                        orthodontist: {
                          ...this.props.selectedMedicalRecord.pdfs?.orthodontist,
                          comment: e.target.value,
                        },
                      });
                    }}
                  />
                ),
              },
            ],
          },
          {
            fields: [
              {
                html: (
                  <p>
                    共有先への
                    <br />
                    PDF添付
                  </p>
                ),
              },
              {
                html: (
                  <div className="horizontal blockPdf">
                    <FilePicker
                      className="btnpdf-wrapper relative"
                      labelClassName="btn btn-primary btn-main"
                      id="pdf2"
                      hasFile={hasData(this.props.selectedMedicalRecord.pdfs?.orthodontist.file)}
                      labelText={
                        this.props.selectedMedicalRecord.pdfs?.orthodontist &&
                        getFileNameFromMFile(
                          Strings.SELECT_FILE,
                          this.props.selectedMedicalRecord.pdfs?.orthodontist.file
                        )
                      }
                      inputClassName="w300px form-control mr10"
                      onDelete={() => {
                        this.props.onChangeMedicalRecordData("pdfs", {
                          ...this.props.selectedMedicalRecord.pdfs,
                          orthodontist: {
                            ...this.props.selectedMedicalRecord.pdfs?.orthodontist,
                            file: {
                              ...this.props.selectedMedicalRecord.pdfs?.orthodontist.file,
                              isUpdate: true,
                              path: "",
                              base64: "",
                              name: "",
                            },
                          },
                        });
                      }}
                      onChange={(_: any, __: any, inputfile: any, url?: string) => {
                        if (inputfile) {
                          const file = mFileFromJson({
                            isUpdate: true,
                            path: url,
                            file: inputfile,
                            name: inputfile.name,
                          });
                          this.props.onChangeMedicalRecordData("pdfs", {
                            ...this.props.selectedMedicalRecord.pdfs,
                            orthodontist: {
                              ...this.props.selectedMedicalRecord.pdfs?.orthodontist,
                              file: file,
                            },
                          });
                        }
                      }}
                      fileAccept={FileTypes.PDF}
                    />
                  </div>
                ),
              },
            ],
          },
        ]}
      ></Table>
    );
  };

  updateGalary = (
    type: string,
    value: string,
    label: string,
    e?: React.ChangeEvent<HTMLInputElement>,
    inputfile?: any,
    url?: string
  ) => {
    if ((e?.target.files && e?.target.files.length > 0) || inputfile) {
      const imageWithEmptyPath = this.props.selectedMedicalRecord.images?.filter(
        (image) => image.image_type === type && image.path === ""
      )[0];
      const imageWithType = this.props.selectedMedicalRecord.images?.filter((image) => image.image_type === type)[0];

      if (imageWithEmptyPath) {
        const imageIndex = this.props.selectedMedicalRecord.images.indexOf(imageWithEmptyPath);
        this.props.selectedMedicalRecord.images[imageIndex].path = url!;
        this.props.selectedMedicalRecord.images[imageIndex].image_type = type;
        this.props.selectedMedicalRecord.images[imageIndex].label = label!;
        this.props.selectedMedicalRecord.images[imageIndex].file_type = inputfile.extension ?? inputfile.type;
        this.props.selectedMedicalRecord.images[imageIndex].name =
          inputfile.name ||
          `sample${new Date().getTime()}.${value.substring("data:image/".length, value.indexOf(";base64"))}`;
      } else if (imageWithType) {
        this.props.selectedMedicalRecord.images.map((imageUpdate) => {
          if (imageUpdate.image_type === type) {
            imageUpdate.path = url!;
            imageUpdate.label = label!;
            imageUpdate.file_type = inputfile.extension ?? inputfile.type;
            imageUpdate.name =
              inputfile.name ||
              `sample${new Date().getTime()}.${value.substring("data:image/".length, value.indexOf(";base64"))}`;
          }

          return imageUpdate;
        });
      } else {
        const newImage = mFileFromJson({
          isUpdate: true,
          path: url,
          label: label,
          image_type: type,
          file_type: inputfile.extension ?? inputfile.type,
          name:
            inputfile.name ||
            `sample${new Date().getTime()}.${value?.substring("data:image/".length, value.indexOf(";base64"))}`,
        });

        this.props.selectedMedicalRecord.images.push(newImage);
      }

      this.props.onChangeMedicalRecordData("images", this.props.selectedMedicalRecord.images);
    }
  };

  updateMultipleGallery = (event?: React.ChangeEvent<HTMLInputElement>, fileList?: any[]) => {
    fileList?.forEach((file) => {
      let isFileUpdate = false;

      galleryLoop: for (let i = 0; i < this.state.listGallery.length; i++) {
        const gallery = this.state.listGallery[i];

        const isGalleryEmpty = this.props.selectedMedicalRecord.images.every(
          (image) => gallery.name !== image.image_type
        );

        if (isGalleryEmpty) {
          this.updateGalary(gallery.name, file.valueBase64, gallery.label!, event, file, file.location);

          isFileUpdate = true;
          break;
        }

        for (let j = 0; j < this.props.selectedMedicalRecord.images.length; j++) {
          const medicalRecordImage = this.props.selectedMedicalRecord.images[j];

          if (gallery.name === medicalRecordImage.image_type && medicalRecordImage.path === "") {
            this.updateGalary(gallery.name, file.valueBase64, gallery.label!, event, file, file.location);

            isFileUpdate = true;
            break galleryLoop;
          }
        }
      }

      if (!isFileUpdate) {
        const nonDentalFields = [...this.state.listGallery.filter((field) => field.name.indexOf("dental") !== 0)];
        let dentalFields = [...this.state.listGallery.filter((field) => field.name.indexOf("dental") === 0)];

        const newDentalField = {
          name: `dental${dentalFields.length}`,
          type: "file",
          label: "デンタルデータ",
        };
        dentalFields = [...dentalFields, newDentalField];

        this.setState(
          {
            ...this.state,
            listGallery: [...nonDentalFields, ...dentalFields],
          },
          () => {
            this.updateGalary(newDentalField.name, file.valueBase64, newDentalField.label!, event, file, file.location);
          }
        );
      }
    });
  };

  saveMedicalRecord = async (record: IMedicalRecord) => {
    if (!record.id) {
      await this.props.onAddMedicalRecord(record);
      return;
    }
    await this.props.onUpdateMedicalRecord(record);
  };

  handleSelectPatient = (
    patient: SingleValue<{
      value: string;
      label: string;
    }>
  ) => {
    this.props.onSelectPatients(patient?.label!, patient?.value!).then((result) => {
      this.props.onChangeMedicalRecordData("patient", result);

      const nonDentalImages = this.props.selectedMedicalRecord.images.filter(
        (image) => image.id !== 0 && image.image_type.indexOf("dental") === -1
      );
      this.props.onChangeMedicalRecordData("images", nonDentalImages);

      this.setState(
        (prevState) => ({
          ...this.state,
          listGallery: [
            ...prevState.listGallery.filter((gallery) => gallery.name.indexOf("dental") === -1),
            ...prevState.listGallery.filter((gallery) => gallery.name === "dental"),
          ],
        }),
        () => {
          result.matched_name_images.forEach((image: any, imageIndex: number) => {
            this.setState(
              (prevState) => ({
                ...this.state,
                listGallery: [
                  ...prevState.listGallery,
                  { name: `dental${imageIndex + 1}`, type: "file", label: image.name },
                ],
              }),
              () => {
                this.updateGalary(`dental${imageIndex + 1}`, image.url, image.name, undefined, image, image.url);
              }
            );
          });
        }
      );
    });
  };

  render() {
    let plyKeys = Array.from(this.props.selectedMedicalRecord.files.keys()).filter((key) => {
      let item = this.props.selectedMedicalRecord.files.get(key);
      return key.indexOf("ply") > -1 && item && (item.blob || item.path);
    });

    let stlKeys = Array.from(this.props.selectedMedicalRecord.files.keys()).filter((key) => {
      let item = this.props.selectedMedicalRecord.files.get(key);
      return key.indexOf("ply") === -1 && item && (item.blob || item.path);
    });
    return (
      <MainLayout
        isLoading={
          this.props.medicalRecordState === MedicalRecordState.addingMedicalRecord ||
          this.props.medicalRecordState === MedicalRecordState.updatingMedicalRecord ||
          this.props.medicalRecordState === MedicalRecordState.convertingFiles
        }
        className="secondpage navstate_show page-medical_management detail animate-item"
        subClassName="p-medical_management detail medical_management-fix"
      >
        <DragDropContext onDragEnd={this.handleDragEnd!}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header flex-H">
                <ReactSVG
                  src={Assets.back}
                  fill="white"
                  onClick={() => {
                    history.replace(Routes.MEDICAL_MANAGEMENT);
                  }}
                />
                {Strings.MEDICAL_MANAGEMENT +
                  Strings.LINE +
                  (findSubRouteFromPathName(window.location.pathname) === Routes.MEDICAL_MANAGEMENT_CREATE
                    ? Strings.CREATE_NEW
                    : Strings.EDIT)}
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.saveMedicalRecord(this.props.selectedMedicalRecord);
                }}
              >
                <div className="card-body">
                  <NoDataWrapperComponent
                    isNoData={this.props.selectedMedicalRecord.isNotFound}
                    noDataContent={<h3>{Strings.NO_DATA}</h3>}
                  >
                    <LoadingComponent
                      isLoading={this.props.medicalRecordState === MedicalRecordState.fetchingMedicalRecord}
                    >
                      {this.buildMedicalRecordDetailTable(plyKeys, stlKeys)}
                    </LoadingComponent>
                    <div className="fec mt20 btn-group-end">
                      <MainButton
                        enabled={
                          this.props.medicalRecordState !== MedicalRecordState.addingMedicalRecord &&
                          this.props.medicalRecordState !== MedicalRecordState.updatingMedicalRecord &&
                          this.props.selectedMedicalRecord.patient.id !== ""
                        }
                        isSubmitButton
                        title={Strings.REGISTER}
                        submitting={
                          this.props.medicalRecordState === MedicalRecordState.addingMedicalRecord ||
                          this.props.medicalRecordState === MedicalRecordState.updatingMedicalRecord ||
                          this.state.isConverting
                        }
                        className="btn btn-primary small-button mr10"
                        onSubmit={() => {}}
                      />
                      <Link className="btn btn-danger" to={Routes.MEDICAL_MANAGEMENT} replace>
                        {Strings.RETURN}
                      </Link>
                    </div>
                  </NoDataWrapperComponent>
                </div>
              </form>
            </div>
          </div>
        </DragDropContext>
        <MedicalPopup />
        <NewImageNamePopup
          onSubmitName={(newImageName: string) => {
            let nonDentalFields = [...this.state.listGallery.filter((field) => field.name.indexOf("dental") !== 0)];
            let dentalFields = [...this.state.listGallery.filter((field) => field.name.indexOf("dental") === 0)];
            dentalFields = [...dentalFields, { name: "dental", type: "file", label: newImageName }];
            dentalFields.forEach((field, index) => {
              field.name = "dental" + (index === 0 ? "" : index.toString());
            });
            this.setState({
              ...this.state,
              listGallery: [...nonDentalFields, ...dentalFields],
            });
          }}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ app, medicalRecord }: IRootState) => {
  const { currentModal } = app;
  const {
    selectedMedicalRecord,
    searchedPatients,
    searchedOrthodontists,
    isSubmitting,
    attributes,
    medicalRecordState,
  } = medicalRecord;
  return {
    currentModal,
    selectedMedicalRecord,
    searchedPatients,
    searchedOrthodontists,
    isSubmitting,
    attributes,
    medicalRecordState,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    selectedRecordChanged: (newRecord: IMedicalRecord) => dispatch(selectedMedicalRecordChanged(newRecord)),
    onSelectMedicalRecord: (recordId: any) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onSelectMedicalRecord(dispatch, recordId),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onChangeMedicalRecordData: (key: string, value: any) => dispatch(selectedMedicalRecordDataUpdated(key, value)),
    updateMedicalSubmiting: (isSubmiting: boolean) => dispatch(updateMedicalSubmiting(isSubmiting)),
    selectedToothChanged: (toothIndex: number) => dispatch(selectedToothChanged(toothIndex)),
    onConvertPlyFiles: (files: Map<string, any>) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .convertPlyFilesToGlbFromFileInput(dispatch, files),
    onSearchListPatients: (keyword: string) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onSearchListPatients(dispatch, keyword),
    onSelectPatients: (keyword: string, specialCode: string) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onSelectPatients(dispatch, keyword, specialCode),
    onSearchOthordontists: (keyword: string) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onSearchOrthodontist(dispatch, keyword),
    onAddMedicalRecord: (medical: IMedicalRecord) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onAddMedicalRecord(dispatch, medical),
    onUpdateMedicalRecord: (medical: IMedicalRecord) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onUpdateMedicalRecord(dispatch, medical),
    onFetchAttributes: () =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onFetchAttributes(dispatch),
    patientChanged: (patient: IUser) => dispatch(selectedPatientUpdated(patient)),
    medicalRecordStateChanged: (state: MedicalRecordState) => dispatch(medicalRecordStateChanged(state)),
  };
};

type MedicalManagementDetailType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(MedicalManagementDetail);
